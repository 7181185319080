import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import { Helmet }  from 'react-helmet';

import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';

function ThanksVoucherPage(props) {
  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex'/>
        <meta name="robots" content="nofollow"/>
      </Helmet>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading>Vielen Dank für Ihre Bestellung</Heading>
        <p>Ihre Bestellung ist bei uns eingegangen. Sie erhalten von uns in Kürze weitere Informationen zum
          Zahlungsverlauf.</p>
        <p>Ihr Team von Juwelier Leiber.</p>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default ThanksVoucherPage;

export const query = graphql`
  query {
    headerImage: file(relativePath: {eq: "headers/gutschein.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;